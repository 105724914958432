/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import styled from 'styled-components';
import Degen482 from '../../static/MetaHero-482.png';
import Degen1143 from '../../static/MetaHero-1143.png';
import Degen2154 from '../../static/MetaHero-2154.png';
import Degen5484 from '../../static/MetaHero-5484.png';
import Degen5485 from '../../static/MetaHero-5485.png';

const Container = styled.div`
    display: flex; 
    flex-direction: row;
    height: auto;
    margin-top: 4%;
    min-height: 48vh;
    width: 96vw;

    @media (min-width: 1800px) { 
      margin-top: 6%;
    }

    @media (max-width: 1200px) { 
        flex-direction: column;
    }
`;

const DegenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 48px auto;
  justify-content: center;
  max-height: 280px;

`;

const Description = styled.div`
  flex: 1;
  padding: 24px 0 0 0;
`;

const DegenName = styled.a`
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-family: 'VT323';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
  color: #FFFFFF;

  &:hover { 
      text-decoration-line: underline;
      opacity: 0.7;
  }
  
  @media (max-width: 1200px) { 
      margin: 24px;
  }
`;

const Avatar = styled.img`
  flex: 0;
  width: 220px;
  max-width: 16vw;
  margin: 0;
  padding: 0;

  @media (max-width: 1200px) { 
      max-width: none;
  }
`;

// eslint-disable-next-line func-names
const Degen = function (props) {
  return (
    <DegenWrapper>
      <Description>
        <DegenName href={props.url} target="_blank">
          {props.DegenName}
          {' '}
          - #
          {props.DegenNumber}
        </DegenName>
      </Description>
      <Avatar src={props.avatar} alt="" />
    </DegenWrapper>
  );
};

class DegenList extends React.Component {
  render() {
    return (
      <Container>
        <Degen
          DegenName="Frostbite"
          DegenNumber="482"
          avatar={Degen482}
          url="https://opensea.io/assets/0x6dc6001535e15b9def7b0f6a20a2111dfa9454e2/482"
        />
        <Degen
          DegenName="AMETHYST"
          DegenNumber="1143"
          avatar={Degen1143}
          url="https://opensea.io/assets/0x6dc6001535e15b9def7b0f6a20a2111dfa9454e2/1143"
        />
        <Degen
          DegenName="CRYPTA"
          DegenNumber="2154"
          avatar={Degen2154}
          url="https://opensea.io/assets/0x6dc6001535e15b9def7b0f6a20a2111dfa9454e2/2154/"
        />
        <Degen
          DegenName="NEUROID"
          DegenNumber="5484"
          avatar={Degen5484}
          url="https://opensea.io/assets/0x6dc6001535e15b9def7b0f6a20a2111dfa9454e2/5484"
        />
        <Degen
          DegenName="CYPHER"
          DegenNumber="5485"
          avatar={Degen5485}
          url="https://opensea.io/assets/0x6dc6001535e15b9def7b0f6a20a2111dfa9454e2/5485"
        />
      </Container>
    );
  }
}

export default DegenList;
