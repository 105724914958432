/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Helmet } from 'react-helmet';
import { createGlobalStyle } from 'styled-components';
import DegenFavIcon from '../../static/MetaHero-482.png';
import PreviewImage from '../../static/MetaDegen-Twitter-Header.jpg';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #FF6262;
    margin: 0;
    max-height: 100vh;
    width: 100vw;
    max-width: 100%;
  }

  @media (max-width: 900px){ 
      body{
          max-height: none;
      }
  }
`;

// eslint-disable-next-line react/function-component-definition
export default function Layout({ children }) {
  return (
    <>
      <GlobalStyle />
      <Helmet>
        {/* General */}
        <meta charSet="utf-8" />
        <title>MetaDegens</title>
        <meta name="title" content="MetaDegens" />
        <meta name="description" content="Next Generation Comic Book Characters, Movie Stars, and more, powered by MetaHero NFTs" />
        <link rel="canonical" href="https://metadegens.xyz" />
        <link rel="icon" type="image/jpg" href={DegenFavIcon} />
        {/* OpenGraph */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://metadegens.xyz" />
        <meta property="og:title" content="MetaDegens" />
        <meta property="og:description" content="Next Generation Comic Book Characters, Movie Stars, and more, powered by MetaHero NFTs" />
        <meta property="og:image" itemProp="image" content={PreviewImage} />
        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://metadegens.xyz" />
        <meta property="twitter:title" content="MetaDegens" />
        <meta property="twitter:description" content="Next Generation Comic Book Characters, Movie Stars, and more, powered by MetaHero NFTs" />
        <meta property="twitter:image" content={PreviewImage} />
        <meta name="twitter:site" content="@Meta_Degens" />
      </Helmet>
      {children}
    </>
  );
}
