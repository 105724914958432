/* eslint-disable react/prefer-stateless-function */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-filename-extension */
import styled from 'styled-components';
import React from 'react';
import TwitterIcon from '../../static/twitter@256px.png';
import DiscordIcon from '../../static/discord@256px.png';

const Container = styled.div`
  margin: 0 0 48px 0;
  padding: 0;
  width: 100%;
  bottom: 24px;
  font-family: Sora;
  font-style: normal;
  font-weight: normal;

  @media (max-width: 900px) { 
      margin-top: 48px;
  }
`;

const Content = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    @media (max-width: 1200px) { 
        flex-direction: column;
    }
`;

const Contact = styled.p`
    text-align: center;
    color: black;

    a { 
        color: black;
        
        &:hover { 
            opacity: 0.7;
        }
    }
`;

const BuiltWith = styled.p`
    text-align: center;
    color: black;
`;

const Socials = styled.div`
    margin: 0 22px 0 0;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 1200px){
        margin: 24px 0 0 0;
    }
`;

const Twitter = styled.a`
    background-image: url(${TwitterIcon});
    background-repeat: no-repeat;
    background-size: 50px;
    padding: 0 24px 0 0;
    height: 50px;
    width: 50px;

    &:hover { 
        opacity: 0.7;
    }

    @media (max-width: 1200px) { 
        padding: 0 24px 0 0;
    }
`;

const Discord = styled.a`
    background-image: url(${DiscordIcon});
    background-repeat: no-repeat;
    background-size: 50px;
    padding: 0 24px 0 0;
    height: 50px;
    width: 50px;

    @media (max-width: 1200px) { 
        padding: 0 0 0 24px;
    }
`;

class Footer extends React.Component {
  render() {
    return (
      <Container>
        <Content>
          <Contact>
            Want to join the team?
            {' '}
            {' '}
            <a href="mailto:jacob@metadegens.xyz" target="_blank" rel="noreferrer">Get in touch!</a>
          </Contact>
          <BuiltWith>
            Built with
            {' '}
            {'❤️ '}
            {' '}
            {' '}
            in the Metaverse
          </BuiltWith>
          <Socials>
            <Twitter href="https://twitter.com/meta_degens" target="_blank" />
            <Discord href="#" target="_blank" />
          </Socials>
        </Content>
      </Container>
    );
  }
}

export default Footer;
