/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/function-component-definition */
import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import Title from '../components/title';
import DegenList from '../components/degenList';
import Footer from '../components/footer';

const Container = styled.div`
  margin: 0;
  padding: 0;
  width: 100vw;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default function Index() {
  return (
    <Layout>
      <Container>
        <Title />
        <DegenList />
        <Footer />
      </Container>
    </Layout>
  );
}
