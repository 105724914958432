/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import styled from 'styled-components';
import Layout from './layout';
import MetaDegensLogo from '../../static/MetaDegens-Logo.png';

const Container = styled.div`
  margin: 48px 0 0 0;
  padding: 0;
  height: auto;
  width: 100vw;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const TitleSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    width: 80%;

    img { 
        height: 300px;
    }

    @media (max-width: 1200px){ 
        img { 
            height: 220px;
        }
    }

    @media (max-width: 900px){ 
        flex-direction: column;
        align-items: center;
        img { 
            height: auto;
            width: 80%;
            max-width: 400px;
        }
    }
`;

const TitleText = styled.h1`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    a{
        margin: 80px 60px;
        font-family: Sora;
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        line-height: 42px;
        color: #FFFFFF;
        text-decoration: none;
        display: block;
        width: 600px;
        text-shadow: 0 0 1.6px #000; /* horizontal-offset vertical-offset 'blur' colour */

        &:hover { 
            opacity: 0.7;
        }

        span {
            text-decoration: underline;
            color: #fff;
        }
    }

    @media (max-width: 1200px) { 
        a { 
            font-size: 22px;
            line-height: 32px;
            width: 400px;
        }
    }

    @media (max-width: 900px) { 
        a { 
            margin: 36px 0;
            width: 82%;
            font-size: 22px;
            line-height: 28px;
        }
    }
`;

class Title extends React.Component {
  render() {
    return (
      <Layout>
        <Container>
          <TitleSection>
            <img src={MetaDegensLogo} alt={this.props.pageTitle} />
            <TitleText>
              <a href="https://opensea.io/collection/metahero-generative" target="_blank" rel="noreferrer">
                Next Generation Comic Book Characters, Movie Stars, and more, powered by
                {' '}
                <span>MetaHero NFTs</span>
              </a>
            </TitleText>
          </TitleSection>
        </Container>
      </Layout>
    );
  }
}

export default Title;
